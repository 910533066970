import Modal from "react-bootstrap/Modal";

interface WriteToUsProps {
    isActive: boolean;
    title: string;
    message: string;
    handleClose: (arg: boolean) => void
}

const CustomMessage = ({ isActive, handleClose, title, message }: WriteToUsProps) => {    
    

    return (
        <Modal show={isActive} onHide={() => handleClose(false)} >
            <div className="p-4">
            <Modal.Header className="p-0 border-0 d-flex justify-content-between align-items-start">
                <h4 className="fs-18 fw-700 m-0">
                {title}
                </h4>
              <img src="../../assets/images/cross-circle.png" alt="" className="hw-30" />
            </Modal.Header>
            <Modal.Body className="p-0 mb-4">       
                {message}
            </Modal.Body>

            <Modal.Footer className="border-0 justify-content-start p-0">
                <button className="btn btn-secondary" onClick={() => handleClose(false)} >
                Close
                </button>
            </Modal.Footer>
            </div>
        </Modal>
    );
};

export default CustomMessage;