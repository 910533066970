// src/pages/NotFound.tsx
import React from "react";
import { Container } from "react-bootstrap";
import LoginSidebarComponent from "../login-sidebar/login-sidebar";

const ErrorPage: React.FC = () => {
  return (
    <>
      <div className="login-wrapper">
        <div className="inner-wrapper d-flex flex-column flex-lg-row vh-100 overflow-lg-hidden">
          <div className="div">
            <LoginSidebarComponent />
          </div>
          <div className="login-right-side flex-fill h-100 overflow-lg-auto">
            <Container className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
              {" "}
              <h1 style={{ color: "red" }}>Oops! Something went wrong.</h1>{" "}
              <p>We couldn't load the page. Please refresh or try again later.</p>{" "}
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
