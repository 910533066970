import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmationModalProps {
    show: boolean;
    message: string;
    onConfirm: (confirmed: boolean) => void;
    onHide: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ show, message, onConfirm, onHide }) => {
    const handleConfirm = (confirmed: boolean) => {
        onConfirm(confirmed);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => handleConfirm(false)}>No</Button>
                <Button variant="primary" onClick={() => handleConfirm(true)}>Yes</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
