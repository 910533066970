import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlternativeEmail, AlternativeMobile, DashboardTabType, FAQs, Reason, StatusType, Toaster } from '../../types';
import { default_toaster_values } from '../../service/constants';
import { resetStore } from '../actions/global.action';
import { alternativeEmailList, alternativeMobileList, faqsList, reasonsList } from '../actions';

interface SharedInitialState {
  toaster: Toaster | null;
  error?: string | null;
  flag?: number | null;
  dashboardTab: DashboardTabType,
  toggleMobileSidebar: Boolean,
  toggleContractList: Boolean,
  reasons_list: Reason[],
  alternative_emails: AlternativeEmail[],
  alternative_mobile: AlternativeMobile[],
  faq_list: FAQs[],
  status: StatusType | null;
}

const initialState: SharedInitialState = {
  toaster: default_toaster_values,
  error: null,
  flag: null,
  dashboardTab: DashboardTabType.OVERVIEW,
  toggleMobileSidebar: false,
  toggleContractList: false,
  reasons_list: [],
  alternative_emails: [],
  alternative_mobile: [],
  faq_list: [],
  status: null
} as SharedInitialState;

const sharedReducer = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    showToaster: (state, action: PayloadAction<Toaster>) => {
      state.toaster = {
        status: action.payload.status,
        message: action.payload.message,
        type: action.payload.type,
        duration: action.payload.duration
      }
    },
    switchDashboardTab: (state, action: PayloadAction<DashboardTabType>) => {
      state.dashboardTab = action.payload;
    },
    toggleMobileBar: (state) => {
      state.toggleMobileSidebar = !state.toggleMobileSidebar
    },
    toggleContractList: (state) => {
      state.toggleContractList = !state.toggleContractList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, () => initialState)
    .addCase(reasonsList.pending, (state) => {
      state.status = StatusType.Loading;
    })
    .addCase(reasonsList.fulfilled, (state, action: PayloadAction<Reason[]>) => {
      state.reasons_list = action.payload && action.payload.length ? action.payload : [];
    })
    .addCase(reasonsList.rejected, (state, action) => {
      state.status = StatusType.Failed;
      const error: string = typeof action.payload === 'object' && action.payload !== null ? '' : (action.payload as string);
      state.error = error || 'Failed to fetch reason list';
      state.flag = 401;
    })
    .addCase(faqsList.pending, (state) => {
      state.status = StatusType.Loading;
    })
    .addCase(faqsList.fulfilled, (state, action: PayloadAction<FAQs[]>) => {
      state.faq_list = action.payload && action.payload.length ? action.payload : [];
    })
    .addCase(faqsList.rejected, (state, action) => {
      state.status = StatusType.Failed;
      const error: string = typeof action.payload === 'object' && action.payload !== null ? '' : (action.payload as string);
      state.error = error || 'Failed to fetch FAQs list';
      state.flag = 401;
    })
    .addCase(alternativeEmailList.pending, (state) => {
      state.status = StatusType.Loading;
    })
    .addCase(alternativeEmailList.fulfilled, (state, action: PayloadAction<AlternativeEmail[]>) => {
      state.alternative_emails = action.payload && action.payload.length ? action.payload : [];
    })
    .addCase(alternativeEmailList.rejected, (state, action) => {
      state.status = StatusType.Failed;
      const error: string = typeof action.payload === 'object' && action.payload !== null ? '' : (action.payload as string);
      state.error = error || 'Failed to fetch Alternative Emails list';
      state.flag = 401;
    })
    .addCase(alternativeMobileList.pending, (state) => {
      state.status = StatusType.Loading;
    })
    .addCase(alternativeMobileList.fulfilled, (state, action: PayloadAction<AlternativeMobile[]>) => {
      state.alternative_mobile = action.payload && action.payload.length ? action.payload : [];
    })
    .addCase(alternativeMobileList.rejected, (state, action) => {
      state.status = StatusType.Failed;
      const error: string = typeof action.payload === 'object' && action.payload !== null ? '' : (action.payload as string);
      state.error = error || 'Failed to fetch Alternative Emails list';
      state.flag = 401;
    })
  },
});

export default sharedReducer.reducer;
export const { showToaster, switchDashboardTab, toggleMobileBar, toggleContractList } = sharedReducer.actions;
