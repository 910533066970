import React from 'react'
import { PaymentModal } from '../../../types/contract.type';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { RootState } from '../../../redux/reducers';
import { selectPaymentDetails } from '../../../redux/selectors';
import { setActivePaymentModal, setPartialAmount } from '../../../redux/reducers/payment.reducer';

interface EditCardComponentProps {
    openedFrom?: PaymentModal;
    partial_amount?: number;
}

export const EditCardComponent: React.FC<EditCardComponentProps> = ({ openedFrom, partial_amount }) => {
    const dispatch =useDispatch<AppDispatch>();
    const { default_payment_methods, selectedPaymentMethod } = useSelector((state: RootState) => selectPaymentDetails(state));

    const shown_card = selectedPaymentMethod || default_payment_methods;

    // if(shown_card?.payment_source_type === 1){
    //         console.log("/assets/card-type/bank.svg");
    // }else{
    //    console.log(`/assets/card-type/${shown_card?.brand || 'no_card'}.svg`);
    // }

    // console.log(shown_card);
    return (
      <div className="d-flex align-items-center me-2 py-1 px-2 border radius-4 fs-14">
        
        <img
          src={

            (shown_card?.payment_source_type === 1)
            ? "/assets/card-type/bank.svg"
            :
              `/assets/card-type/${shown_card?.brand.toLowerCase() || 'no_card'}.svg`
          }
          alt="Card Type"
          className="radius-8 bank-img me-2"
        />
        {!shown_card ? "Select card" : `${shown_card?.payment_source} ****`}{" "}
        {shown_card?.last4}
        <img
          src="../../assets/images/edit-pencil.png"
          className="ms-1 cursor"
          alt=""
          onClick={() =>{
              dispatch(setActivePaymentModal(PaymentModal.PAYMENT_SOURCES))
              dispatch(setPartialAmount(partial_amount));
            }
          }
        />
      </div>
    );
}

export default EditCardComponent