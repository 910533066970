import React, { useState } from "react";
import {
  Row,
  Col,
  Tabs,
  Tab,
  Container,
} from "react-bootstrap";
import "./support-component.scss";
import { Link, useNavigate } from "react-router-dom";
import WriteToUs from "../ModalComponent/WriteToUs.component";
import SpeakWithExpert from "../ModalComponent/SpeakWithExpert.component";
import { routes_constants } from "../../service/constants";
import { RedirectFrom } from "../../types";


const SupportComponent: React.FC = () => {
  const [writeToUs, setWriteToUs] = useState(false);
  const [speakWithExpert, setSpeakWithExpert] = useState(false);

  const navigate = useNavigate();

  const redirectReportProblem = () => {
    navigate(routes_constants.report_problem);
  }

  return (
    <>
      {writeToUs && (
        <WriteToUs isActive={writeToUs} handleClose={setWriteToUs}  />
      )}
      {speakWithExpert && (
        <SpeakWithExpert isActive={speakWithExpert} handleClose={setSpeakWithExpert}  />
      )}
      <div className="support-wrapper vh-100 overflow-hidden">
        <div className="support-header hw-65 w-100 d-flex align-items-center justify-content-between border-bottom px-4 py-2">
          <img src="../../assets/images/FM-logo.svg" alt="" />
          <img src="../../assets/images/bell-icon.png" alt="" />
        </div>
        <div className="support-content d-flex align-items-start w-100 h-100 p-3">
          <div className="d-flex align-items-center">
            <Link to={routes_constants.dashboard} state={{ from: RedirectFrom.NEED_HELP }} className="decoration-none">
            <img
            src="../../assets/images/back-circle.png"
            className="hw-40 rounded-circle me-3"
            alt=""
            />
            </Link>
            <Link to={routes_constants.dashboard} state={{ from: RedirectFrom.NEED_HELP }} className="decoration-none d-none d-md-block">
            <p className="fs-16 fw-700 m-0 text-secondary">Back to dashboard</p>
            </Link>
          </div>
            <div className="support-inner flex-fill h-100 overflow-auto hide-scroll-bar">
              <Container>
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 border-0"
              >
             
                <Tab eventKey="home" title="Help Center">
                  <div className="container-xl pt-md-5 pt-3 px-0">
                    <div className="w-100 text-center mb-5">
                      <h4 className="fs-24 fw-500 m-0 mb-2">
                        Welcome to the Finance Mutual Help Community
                      </h4>
                      <p className="fs-16 fw-400 m-0 text-muted">
                        For personalized support, reach out to our team. We're
                        here <br /> to help every step of the way.
                      </p>
                    </div>
                    <Row>
                      <Col sm={4} md={6} lg={4} className="mb-4">
                        <div className="p-4 radius-12 border text-center">
                          <img
                            src="../../assets/images/email-icon.png"
                            alt=""
                            className="mb-3 hw-65 rounded-circle"
                          />
                          <h4 className="fs-16 fw-600 m-0 mb-1">
                            Feel free to email us at
                          </h4>
                          <p className="fs-16 fw-400 m-0 text-primary">
                            support@financemutual.com
                          </p>
                        </div>
                      </Col>
                      <Col sm={4} md={6} lg={4} className="mb-4" onClick={() => setWriteToUs(true)}>
                        <div className="p-4 radius-12 border text-center cursor">
                          <img
                            src="../../assets/images/message-icon.png"
                            alt=""
                            className="mb-3 hw-65 rounded-circle"
                          />
                          <h4 className="fs-16 fw-600 m-0 mb-1">
                            Write to Finance Mutual
                          </h4>
                          <p className="fs-16 fw-400 m-0 text-muted">
                            Contact us for any questions or feedback.
                          </p>
                        </div>
                      </Col>
                      <Col sm={4} md={6} lg={4} className="mb-4" onClick={() => setSpeakWithExpert(true)}>
                        <div className="p-4 radius-12 border text-center cursor">
                          <img
                            src="../../assets/images/calling-icon.png"
                            alt=""
                            className="mb-3 hw-65 rounded-circle"
                          />
                          <h4 className="fs-16 fw-600 m-0 mb-1">
                            Speak with an Expert
                          </h4>
                          <p className="fs-16 fw-400 m-0 text-muted">
                            Request a call at your convenience.
                          </p>
                        </div>
                      </Col>
                      <Col sm={4} md={6} lg={4} className="mb-4" onClick={redirectReportProblem}>
                        <div className="p-4 radius-12 border text-center cursor">
                          <img
                            src="../../assets/images/caution-icon.png"
                            alt=""
                            className="mb-3 hw-65 rounded-circle"
                          />
                          <h4 className="fs-16 fw-600 m-0 mb-1">
                            Report a problem
                          </h4>
                          <p className="fs-16 fw-400 m-0 text-muted">
                            Submit a report any issues with your contract.
                          </p>
                        </div>
                      </Col>
                      <Col sm={4} md={6} lg={4} className="mb-4">
                        <div className="p-4 radius-12 border text-center cursor">
                          <img
                            src="../../assets/images/TC-icon.png"
                            alt=""
                            className="mb-3 hw-65 rounded-circle"
                          />
                          <h4 className="fs-16 fw-600 m-0 mb-1">
                            Healthcare Contracts T&C
                          </h4>
                          <p className="fs-16 fw-400 m-0 text-muted">
                            Submit a report any issues with your contract.
                          </p>
                        </div>
                      </Col>
                      <Col sm={4} md={6} lg={4} className="mb-4">
                        <div className="p-4 radius-12 border text-center cursor">
                          <img
                            src="../../assets/images/non-tc-icon.png"
                            alt=""
                            className="mb-3 hw-65 rounded-circle"
                          />
                          <h4 className="fs-16 fw-600 m-0 mb-1">
                            Non-Healthcare Contracts T&C
                          </h4>
                          <p className="fs-16 fw-400 m-0 text-muted">
                            Request a call at your convenience.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Tab>
                {/* TODO: Below feature is pending from backend because of FAQ DATA, wiil enable then! */}
                {/* <Tab eventKey="faq" title="FAQ">
                  <FAQComponent />
                </Tab> */}
              </Tabs>
              </Container>
            </div>
        </div>
      </div>
    </>
  );
};

export default SupportComponent;
