import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectPaymentDetails = createSelector(
  (state: RootState) => state.payment,
  (payment) => ({
    status: payment.status,
    paymentMethods: payment.payment_methods,
    backup_payment_methods: payment.backup_payment_methods,
    default_payment_methods: payment.default_payment_methods,
    changedDateInfo: payment.changedDateInfo,
    activePaymentModal: payment.activePaymentModal,
    activePayNowType: payment.activePayNowType,
    selectedPaymentMethod: payment.selectedPaymentMethod,
    transactionDetails: payment.transactionDetails,
    partial_amount: payment.partial_amount
  })
);

