import React from 'react'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { setActivePaymentModal } from '../../../redux/reducers/payment.reducer';

// interface BackToDashboardProps {
//     // handleClose: (args: boolean) => void;
// }

export const BackToDashboard = () => {
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = () => {
        dispatch(setActivePaymentModal(null));
    };

  return (
    <div className="d-flex flex-column align-items-center gap-3 w-100">
    {/* TODO: Below feature is pending! */}
    {/* <button className="btn border-secondary w-100 fw-600">
    Download Receipt
    </button> */}
    <button
    className="btn btn-secondary  w-100"
    onClick={() => {
        dispatch(setActivePaymentModal(null));
        handleClose();
    }}
    >
    Back To Dashboard
    </button>
</div>
  )
}