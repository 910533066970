import { Alert } from 'react-bootstrap'

export const PaymentNote = () => {
  return (
    <Alert variant="" className="p-0">
    <h5 className="fs-12 fw-600 text-secondary mt-3">Notes:</h5>
    <ul>
        <li className="fs-12 fw-500 text-muted mb-2">
        <strong>$3.00</strong> transaction charges will be
        charged separately for the partial payment.
        </li>
        <li className="fs-12 fw-500 text-muted mb-2">
        You will be charged <strong>a dollar</strong> or{" "}
        <strong>1%</strong> (whichever is higher) as an extra
        transaction fee if you use a non-US debit/credit card.
        </li>
        <li className="fs-12 fw-500 text-muted mb-2">
        Partial payment will reduce the total remaining balance
        and your monthly installment amount.
        </li>
        <li className="fs-12 fw-500 text-muted mb-2">
        It will not reduce the number of payments remaining and
        will not change the due date of the next payment.
        </li>
    </ul>
    </Alert>
  )
}
