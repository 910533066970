import React, { Suspense } from 'react';
import ReportProblemComponent from './report-problem.component';


const LazyReportProblem = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <ReportProblemComponent {...props} />
  </Suspense>
);

export default LazyReportProblem;