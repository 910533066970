import { AppDefaults, Toaster, ToasterType } from "../types";


export const defaultAppDefaults: AppDefaults = {    
    environment: process.env.REACT_APP_SERVER,
    stripe_key: process.env.REACT_APP_STRIPE_KEY || 'pk_test_51QU5GSG0qhxL6Qheuur8KNocxZbXVns1qvqrb9kYW3Z7E2KOzG18w86PYzMWjGqVXuD4YYFcESN351spqp7w4D1Q00EAdpkY6W', 
    // stripe_key: 'pk_test_51QU5GSG0qhxL6Qheuur8KNocxZbXVns1qvqrb9kYW3Z7E2KOzG18w86PYzMWjGqVXuD4YYFcESN351spqp7w4D1Q00EAdpkY6W',
    stripeMerchantInfo: {
      environment: 'TEST',
      merchantId: "BCR2DN4TQSCYVXZJ",
      merchantName: "Credee LLC"
    }
  };

export const default_toaster_values: Toaster = {
  status: false,
  type: ToasterType.Info,
  message: "",
  duration: 3000
}

export const routes_constants = {
  basic_url: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  login: '/login',
  change_password: '/change-password',
  verify: '/verify',
  forgot: '/forgot',
  dashboard: '/app/dashboard',
  partial_payment: '/app/partial_payment',
  profile: '/app/profile',
  support: '/app/support',
  report_problem: '/app/support/report-problem'
}
  

export const closeExisingPaymentSourceModal = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  SELECTED_CARD: 'SELECTED_CARD',
  ADD_CARD: "ADD_CARD"
}