import { ApiResponse, Pagination } from "./common.type";

export interface ContractDetails {
  contract_code: string;
  doctor_id: number;
  pf_id: number;
  contract_id: number;
  treatment_amount: number;
  recurring_amount: number;
  recurring_extra_amount: number;
  recurring_extra_percent: number;
  threshold_amount: number;
  threshold_recurring_extra_amount: number;
  threshold_recurring_extra_percent: number;
  next_payment_date: string;
  practice_name: string;
  doctor_first_name: string;
  doctor_last_name: string;
  contract_type: number;
}

export interface CustomerContractList {
  contract_id: number;
  contract_code: string;
  practice_name: string;
  payment_status: string;
}

export interface ContractService {
  service_id: number;
  contract_id: number;  
  service: string;
  price: number;
}

export interface ContractDetail extends ApiResponse {
  pf_id: number;
  patient_id: number;
  doctor_id: number;
  contract_id: number;
  contract_type: number;
  cancelled_date?: string;
  completed_contract_date?: string;
  interest_per_recurring: number;
  principal_per_recurring: number;
  one_dollar_interest: number;
  adv_one_dollar_interest: number;
  advance_interest_amount: number;
  treatment_amount: number;
  financed_amount: number;
  recurring_amount: number;
  recurring_extra_amount: number;
  recurring_extra_percent: number;
  threshold_amount: number;
  threshold_recurring_extra_amount: number;
  threshold_recurring_extra_percent: number;
  late_fee: number;
  missing_payments: number;
  next_payment_date: string;
  extend_payment_days_patient:any;
  next_missing_payment_date: string;
  source_id: number;
  secondary_source_id: number;
  is_activated: number;
  number_of_payments: number;
  number_of_payments_paid: number;
  interest_rate: number;
  interest_rate_to_show: number;
  remaining_payments: number;
  pf_status: number;
  is_guaranteed: number;
  is_debt_contract: number;
  patient_first_name: string;
  patient_last_name: string;
  patient_email: string;
  patient_mobile_country_code: string;
  patient_mobile: string;
  patient_address: string;
  city_id: number;
  agent_id: number;
  patient_ssn: string;
  date_of_birth: string | null;
  annual_income: number;
  patient_signature: string;
  cocu_id: number;
  stop_payment_reminder: number;
  stop_payment_reminder_sms: number;
  di_fee_per_pay: number;
  ar_ez_fee_per_pay: number;
  risk_type: string;
  date_added: string;
  doctor_record_id: number | null;
  is_payments_paused: number;
  bankrupt_stop_payment: number;
  dispute_payment_status: number;
  edit_contract_count: number;
  dnd: number;
  state: string | null;
  city: string | null;
  zip: string | null;
  is_contract_deferred: number;
  patient_code: string;
  contract_code: string;
  remaining_amount: number;
  doctor_name: string;
  practice_name: string | null;
  practice_email: string | null;
  practice_mobile: string | null;
  practice_mobile_country_code: string | null;
  doctor_email: string;
  doctor_dnd: number;
  doctor_mobile: string;
  doctor_mobile_country_code: string | null;
  pay_to_doctor: number | null;
  payment_status: string;
  enrollment_fee: number;
  apf_recurring_amount: number;
  transfer_amount_paid_by_denefits: number;
  gp_recovered: number;
  disable_edit_contract: number;
  disable_cancel_contract: number;
  settled_payment_to_doctor: number;
  disabled_all_buttons: number;
  actions_json: Actions;
  financeSettings: FinanceSettings;
  language_preferred: string;
  overdue_amount: number;
  number_of_missing_payments: number;
  total_remaining_amount: number;
  doctor_expected_payout: string;
  overdue_recurring_extra_percent: number;
  overdue_recurring_extra_amount: number;
  patient_payoff_amount: number;
  is_doctor_active: number;
  doctor_expected_payoff: number;
  expected_monthly_pay_to_doctor: number;
  deduct_next_payment_late_fees: number;
  next_recurring_amount: number;
  total_balance_remaining: number;
  showMakePayBtn: number;
  is_cash_adjustment: number;
  doctor_address:string;
  practice_address:string;
  pay_to_doctor_till_date: number;
  total_paid_amount: number;
  service_record: ContractService[],
  practice_city: string,
  practice_state: string,
  practice_zip: string
}

export interface ContractListInput extends Pagination {
  access_token?: string | null;
 }

interface Actions {
  pending_recurring_date_attempts: number;
  edit_recurring_date: number;
  download_contract: number;
  download_consent_form: number;
  pay_next_recurring: number;
  charge_payoff: number;
  charge_partial_payment: number;
  contribution_link: number;
  charge_late_fee: number;
  charge_full_payment: number;
}

interface FinanceSettings {
  minimum_amount: number | null;
  maximum_amount: number | null;
  minimum_tenure: number;
  maximum_tenure: number;
}

export interface TransactionType {
  brand: string;
  source_id: number;
  show_to_customer: number;
  transaction_initiated_date: string | null;
  webhook_status: string | null;
  dopa_id: number;
  collection_fee: number;
  payout_fee: number;
  inactive_fee: number;
  doctor_id: number;
  original_payment_date: string;
  charge_id: string;
  amount_deducted: number;
  pay_to_doctor: number;
  pay_to_partner: number;
  stripe_fee: number;
  payment_type: number;
  late_fee: number;
  late_fee_refunded: number;
  application_fee: number;
  date_added: string;
  dispute: string | null;
  dispute_status: string | null;
  dispute_charged_from_business: number | null;
  last_updated: string;
  principal_amount: number;
  interest_amount: number;
  stripe_status: number;
  pt_name: string;
  payment_type_enum: string;
  dopa_refunded_amount: number;
  transaction_fee: number;
  customer_pt_name: string | null;
  business_pt_name: string | null;
  show_amount_deducted: number;
  pay_to_doctor_late_fee: number;
  first_recurring_with_earlier_late_fee: number;
  contract_type: number;
  last4: string;
  payment_status: string;
  charge_status: string;
  pay_to_doctor_status: number;
  is_orp_payment: number;
  paid_by: string;
  payment_date: string;
  payment_source_type: number;
}


export type ChangedDateInfo = {
    amount: number,
    newDate?: Date,
    late_fee_ob: {
        late_fee: number,
        missing_payments: number,
        pf_id: number,
        pf_status: number
    },
    show_connected_option: boolean
}

export enum PaymentModal {
  CHANGE_PAYMENT = "Change payment",
  PAYMENT_SOURCES = "Payment sources",
  ADD_PAYMENT = "Add payment",
  ADD_CARD = "Add card",
  PAY_NOW = "Pay Now"
}

export enum ContractStatus {
  Active1 = 1,
  Active2 = 2,
  Overdue = 3,
  Completed = 4,
  Cancelled = 5
}