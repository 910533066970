import { GoogleReCaptchaProvider } from '@google-recaptcha/react';
import React, { lazy, Suspense } from 'react';
import { getGoogleCaptchaAPIKey } from '../../service/common';

const LoginComponent = lazy(() => import('./login.component'));

const LazyLoginComponent = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <GoogleReCaptchaProvider
    type="v3"
    siteKey={getGoogleCaptchaAPIKey()}
  >
  <Suspense fallback={null}>    
    <LoginComponent {...props} />
  </Suspense>
  </GoogleReCaptchaProvider>
);

export default LazyLoginComponent;