import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectSharedDetails = createSelector(
  (state: RootState) => state.shared,
  (shared) => ({    
    toaster: {
      status: shared.toaster?.status,
      message: shared.toaster?.message,
      duration: shared.toaster?.duration,
      type: shared.toaster?.type
    },
    dashboardType: shared.dashboardTab,
    toggleMobile: shared.toggleMobileSidebar,
    toggleContracts: shared.toggleContractList,
    reasonList: shared.reasons_list,
    faq_list: shared.faq_list,
    alternative_emails: shared.alternative_emails,
    alternative_mobiles: shared.alternative_mobile,
    status: shared.status
  })
);
