import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InitalState, StatusType } from '../../types/common.type';
import { changeRegisterStep, checkVerificationToken, getVerificationToken, validateCustomer, VerificationTokenResponse } from '../actions/register.action';
import { RegisterSteps } from '../../types/register.type';
import { resetStore } from '../actions/global.action';

interface RegisterInitialState extends InitalState {
  account_approved: number | 0;
  business_name: string;
  contract_code: string;
  estimated_amount: string;
  name: string;
  payment_date: string;
  patient_id: number | null;
  registerStep: RegisterSteps;
  recurring_amount: string;
  verification_status: number;
  verification_token: string;
}

const initialState: RegisterInitialState = {
  account_approved: 0,
  business_name: '',
  contract_code: '',
  estimated_amount: '',
  error: null,
  flag: null,
  name: "",
  patient_id: null,
  registerStep: RegisterSteps.Step3,
  recurring_amount: '',
  payment_date: '',
  verification_token: "",
  verification_status: 1,
  status: StatusType.Idle
} as RegisterInitialState;

const registerReducer = createSlice({
  name: 'register',
  initialState,
  reducers: {
    setVerificationDetails: (state, action) => {
      let currency_symbol: string = '';
      if (action.payload?.country_currency_info && action.payload?.country_currency_info.currency && action.payload?.country_currency_info.currency.length) {
        currency_symbol = action.payload?.country_currency_info?.currency[0]?.display_logo;
      }
      const contract_info = action.payload.contract_info.length ? action.payload.contract_info[0] : null;
      state.business_name = contract_info?.practice_name || '';
      state.contract_code = contract_info?.contract_code || '';
      state.estimated_amount = (contract_info?.treatment_amount || 0).toFixed(2);
      state.patient_id = action.payload.patient_id;
      state.name = `${action.payload.patient_first_name} ${action.payload.patient_last_name}`;
      state.recurring_amount = `${currency_symbol}${((contract_info?.recurring_amount) || 0).toFixed(2)} + ${currency_symbol}${(contract_info?.threshold_recurring_extra_amount || 0).toFixed(2)} `;
      state.payment_date = contract_info?.next_payment_date || '';
      state.verification_token = action.payload.verification_token;
      state.verification_status = action.payload.verification_status;
      state.registerStep = action.payload.verification_status === 3 ? RegisterSteps.Step4 : RegisterSteps.Step3;
    },
    switchRegisterStep: (state, action: PayloadAction<RegisterSteps>) => {
      state.registerStep = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(resetStore, () => initialState)
    .addCase(getVerificationToken.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(getVerificationToken.fulfilled, (state, action: PayloadAction<VerificationTokenResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          }
          else {
            state.status = StatusType.Succeeded;
          }
        }

      })
      .addCase(getVerificationToken.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch verification details';
        state.flag = 401;
      })
      .addCase(checkVerificationToken.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(checkVerificationToken.fulfilled, (state, action: PayloadAction<VerificationTokenResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          } else {
            state.status = StatusType.Succeeded;
            let currency_symbol: string = '';
            if (action.payload?.country_currency_info && action.payload?.country_currency_info.currency && action.payload?.country_currency_info.currency.length) {
              currency_symbol = action.payload?.country_currency_info?.currency[0]?.display_logo || '';
            }
            const contract_info = action.payload.contract_info.length ? action.payload.contract_info[0] : null;
            state.business_name = contract_info?.practice_name || '';
            state.contract_code = contract_info?.contract_code || '';
            state.estimated_amount = (contract_info?.treatment_amount || 0).toFixed(2);
            state.patient_id = action.payload.patient_id;
            state.name = `${action.payload.patient_first_name} ${action.payload.patient_last_name}`;
            state.recurring_amount = `${currency_symbol}${((contract_info?.recurring_amount) || 0).toFixed(2)} + ${currency_symbol}${(contract_info?.threshold_recurring_extra_amount || 0).toFixed(2)} `;
            state.payment_date = contract_info?.next_payment_date || '';
            // state.verification_token = action.payload.verification_token;
            state.verification_status = action.payload.verification_status;
            state.registerStep = RegisterSteps.Step2;
          }
        }

      })
      .addCase(checkVerificationToken.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to fetch verification details';
        state.flag = 401;
      })
      .addCase(changeRegisterStep.fulfilled, (state, action: PayloadAction<RegisterSteps>) => {
        state.registerStep = action.payload;
      })
      .addCase(validateCustomer.pending, (state) => {
        state.status = StatusType.Loading;
        state.error = null;
      })
      .addCase(validateCustomer.fulfilled, (state, action: PayloadAction<VerificationTokenResponse>) => {
        if (!action.payload) { state = initialState; }
        else {
          if (action.payload.is_error === 1) {
            state.error = action.payload.original_text
            state.flag = action.payload.flag
          }
          else {
            state.status = StatusType.Succeeded;
            state.registerStep = RegisterSteps.Step4;
          }
        }

      })
      .addCase(validateCustomer.rejected, (state, action) => {
        state.status = StatusType.Failed;
        state.error = action.payload || 'Failed to validate customer';
        state.flag = 401;
      })
  }
});

export const { setVerificationDetails, switchRegisterStep } = registerReducer.actions;
export default registerReducer.reducer;
