import { ChangedDateInfo } from "./contract.type";

type StripeMerchantInfo = {
    environment: google.payments.api.Environment,
    merchantId: string,
    merchantName: string
}
export interface AppDefaults {
    // app_version: number;
    // app_type: number;
    // device_token: string;
    // device_type: number;
    environment: string | undefined;
    stripe_key: string;
    stripeMerchantInfo: StripeMerchantInfo;
}

export const defaultChangedDateInfo: ChangedDateInfo = {
  amount: 0,
  late_fee_ob: {
    late_fee: 0,
    missing_payments: 0,
    pf_id: 0,
    pf_status: 0
  },
  show_connected_option: false
}