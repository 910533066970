import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import LoginComponent from '../components/login/login.lazy';
import DashboardComponent from '../components/layout/dashboard/dashboard.lazy';
import PrivateRoutes from './PrivateRouting';
import ContractValidateComponent from '../components/contract-validate/contract-validate.component';
import ForgetPasswordLazyComponent from '../components/forget-password/forgot-password.lazy';
import SetPasswordLazyComponent from '../components/contract-validate/set-password/set-password.lazy';
import LayoutLazyComponent from '../components/LayoutComponent/LayoutComponent.lazy';
import { Profile } from '../components/profile/profile.component';
import PartialPaymentLazyComponent from '../components/partial-payment/partial-payment.lazy';
import SupportComponent from '../components/customer-support/support.component';
import LazyReportProblem from '../components/customer-support/report-problem/report-problem.lazy';
import NotFound from '../components/common/404.component';
import ErrorPage from '../components/common/error.component';

const Routing: React.FC = () => {
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
          <Route errorElement={<ErrorPage />} >
            <Route path="/" element={<Navigate to="login"/>} />
            <Route path="/login" element={<LoginComponent />} />
            <Route path="verify/:contract_code" element={<ContractValidateComponent />} />
            <Route path="/change-password" element={<SetPasswordLazyComponent />} />
            <Route path="/forgot" element={<ForgetPasswordLazyComponent />} />
          </Route>
          <Route element={<LayoutLazyComponent />} errorElement={<ErrorPage />} >
            <Route path="/app" element={<PrivateRoutes />} >
              <Route path="dashboard" element={<DashboardComponent />} />  
              <Route path="contract-details/:id" element={<DashboardComponent />} />  
              <Route path="profile" element={<Profile />} />
              <Route path="support" element={<SupportComponent />} />
              <Route path="support/report-problem" element={<LazyReportProblem />} />
              <Route path="partial-payment" element={<PartialPaymentLazyComponent />} />
            </Route>
          </Route>
          <Route path="*" element={<NotFound />} />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default Routing;
