
interface PaymentSuccessProps {
    paid_amount: number
}

export const PaymentSuccessComponent = ({ paid_amount }: PaymentSuccessProps) => {
  return (
    <div className="d-flex flex-column align-items-center px-4 m-0 mb-2 text-center">
    <img
    src="../../assets/images/green-circle.svg"
    className="mb-3"
    alt="Success Tick Icon"
    />
    <p className="fs-20 fw-700 lh-base">
    Payment of ${Number(paid_amount?.toFixed(2))} is <br />{" "}
    completed successfully.
    </p>
    <p className="fs-16 fw-400 lh-lg">
    Thank you for your payment. A confirmation has been sent to your
    email. For any questions or assistance, please contact our
    support team.
    </p>
</div>
  )
}
