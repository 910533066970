// components/common/PrivateRoutes.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { selectAuthDetails } from '../redux/selectors';
import { RootState } from '../redux/reducers';

const PrivateRoutes: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => selectAuthDetails(state));

  return (isAuthenticated) ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
